<ng-container *ngIf="taskSubmission">
  <div>{{ keyedChoiceOptions[taskSubmission.choice].label }}</div>
  <ul class="py-1 ps-4 mb-0">
    <li *ngFor="let reason of taskSubmission.choiceReasons; let i = index">
      <ng-container [ngSwitch]="reason">
        <span *ngSwitchCase="verificationReasons.prescriberReferral"> Reference to the prescriber </span>
        <span *ngSwitchCase="verificationReasons.prescriberOfficeReferral">
          Reference to the prescriber’s office
        </span>
        <span *ngSwitchCase="verificationReasons.specialityReferral">
          Reference to the prescriber's specialty<span *ngIf="taskSubmission.choiceSpecialityReferralSpeciality">:</span>
          <div *ngIf="taskSubmission.choiceSpecialityReferralSpeciality">
            {{ taskSubmission.choiceSpecialityReferralSpeciality}}
          </div>
        </span>
        <span *ngSwitchCase="verificationReasons.other">
          Other<span *ngIf="taskSubmission.choiceOtherReasonNote">:</span>
          <div *ngIf="taskSubmission.choiceOtherReasonNote">
            {{ taskSubmission.choiceOtherReasonNote }}
          </div>
        </span>
        <span *ngSwitchCase="rejectionReasons.ignorePatient">
          Do not show me any further captures for patient
          <strong>{{ patient.fullName }} DOB {{ patient.dob | date: 'MM/dd/yyyy' }} </strong>.
        </span>
        <span *ngSwitchCase="rejectionReasons.ignorePrescriber">
          Do not show me any further captures from prescriber <strong>{{ provider.fullName }}</strong>.
        </span>
        <span *ngSwitchCase="rejectionReasons.ignorePatientPrescriber">
          Do not show me any further captures from prescriber <strong>{{ provider.fullName }}</strong> for patient
          <strong>{{ patient.fullName }} DOB {{ patient.dob | date: 'MM/dd/yyyy' }} </strong>.
        </span>
      </ng-container>
    </li>
  </ul>
</ng-container>
