import { Component, Input, OnInit } from '@angular/core';
import { ReferralVerificationReason } from 'app/core/enums/referral-verification-reason.enum';
import { MinimalProvider } from 'app/core/models/minimal-provider.model';
import { Patient } from 'app/core/models/patient.model';
import { ClientTaskRejectionReason } from 'app/core/enums/client-task-rejection-reason.enum';
import { TaskSubmission } from 'app/core/models/task-submission.model';

@Component({
  selector: 'app-client-referral-task-option-reason',
  templateUrl: './client-referral-task-option-reason.component.html',
  styleUrls: ['./client-referral-task-option-reason.component.scss'],
})
export class ClientReferralTaskOptionReasonComponent implements OnInit {
  verificationReasons = ReferralVerificationReason;
  rejectionReasons = ClientTaskRejectionReason;

  @Input() patient: Patient;
  @Input() provider: MinimalProvider;
  @Input() reason: ReferralVerificationReason | ClientTaskRejectionReason;
  @Input() taskSubmission: TaskSubmission;

  constructor() { }

  ngOnInit() { }

  get otherNote() {
    return this.taskSubmission?.choiceOtherReasonNote;
  }

  get specialityReferralSpeciality() {
    return this.taskSubmission?.choiceSpecialityReferralSpeciality;
  }
}
