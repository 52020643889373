import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Provider } from "app/core/models/provider.model";
import { ProviderService } from 'app/core/services/provider.service';

@Component({
  selector: 'app-capture-admin-provider-encounters',
  templateUrl: './capture-admin-provider-encounters.component.html',
  styleUrls: ['./capture-admin-provider-encounters.component.scss'],
})

export class CaptureAdminProviderEncountersComponent implements OnInit {
  provider: Provider;
  loading: boolean;

  constructor(
    private providerService: ProviderService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.init();
  }

  fetchProviderEncounters = (paging: any, eligible: boolean) => {
    return this.providerService.encounters(this.provider.id, eligible, paging);
  }

  private init() {
    const npi = this.route.snapshot.paramMap.get('npi');
    this.loading = true;

    this.providerService.getByNpi(npi).subscribe(provider => {
      this.provider = provider;
      this.loading = false;
    });
  }
}