import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Encounter } from '../../../core/models/encounter.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { PagedProviderEncounters } from 'app/core/services/provider.service';
import { PagedPatientEncounters } from 'app/core/services/patient.service';
import { EncounterQueueSettings } from 'app/core/models/user-settings/encounter-search-settings.model';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { UserSettingsService } from 'app/core/services/user-settings.service';

@Component({
  selector: 'app-encounter-list',
  templateUrl: './encounter-list.component.html',
  styleUrls: ['./encounter-list.component.scss'],
})

export class EncounterListComponent implements OnInit {
  @Input() fetchEncounters: (paging: any, eligible: boolean) =>
    Observable<PagedProviderEncounters | PagedPatientEncounters>;
  @Input() eligibleCount: number;
  @Input() ineligibleCount: number;

  encounters: Encounter[];
  refreshing = false;
  paging: LimitOffsetPaging;
  eligible = true;
  currentSection: string;

  constructor(
    private userSettingsService: UserSettingsService,
  ) { }

  get viewing() {
    return Math.min(this.paging.pageSize, this.paging.count);
  }

  ngOnInit() {
    this.init();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.saveQueueSettings();
    this.loadEncounters(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.saveQueueSettings();
    this.loadEncounters(page);
  }

  private init() {
    this.paging = LimitOffsetPaging.empty;
    this.loadQueueSettings();
    this.paging.currentPage = 1;
    this.loadEncounters(this.paging.currentPage);
  }

  private loadEncounters(page: number) {
    const pagingParam = { page, pageSize: this.paging.pageSize };

    this.refreshing = true;

    this.fetchEncounters(pagingParam, this.eligible).subscribe(
      (result: PagedPatientEncounters | PagedProviderEncounters) => {
        this.encounters = result.records || [];
        this.paging = result.meta.paging
        this.refreshing = false;
      }
    );
  }

  private loadQueueSettings() {
    const queueSettings: EncounterQueueSettings = this.userSettingsService.get<EncounterQueueSettings>(
      UserSettings.captureAdminEncounterQueueSettings
    );
    if (queueSettings) {
      this.paging = queueSettings.paging;
    }
  }

  private saveQueueSettings(position = null) {
    const queueSettings = new EncounterQueueSettings();
    queueSettings.position = position;
    queueSettings.paging = this.paging;
    this.userSettingsService.save<EncounterQueueSettings>(
      UserSettings.captureAdminEncounterQueueSettings, queueSettings
    );
  }

  onSectionClick(section: string) {
    this.currentSection = section;
  }

  navClick($event: Event, eligible: boolean) {
    $event.preventDefault();
    this.eligible = eligible;
    this.loadEncounters(1);
  }
}
