<ng-container *ngIf="!loading; else: spinner">
  <app-patient-header [patient]="patient" />

  <div class="page-container">
    <div class="mt-3">

      <div class="card">
        <div class="card-body">
          <h5 class="mb-4">Patient Prescriptions</h5>
          <app-prescription-list
            [prescriptions]="prescriptions"
            [small]="true"
            [dark]="true"
            [fixedHeader]="true" />
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #spinner> <mat-spinner /> </ng-template>
