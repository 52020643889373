import { CaptureStatusReason } from '../enums/capture-status-reason.enum';
import { CaptureTransition } from '../enums/capture-transition.enum';
import { CaptureTransitionReasonOption, keyedReasonOptions } from './capture-status-reason.opts';
import { keyBy } from 'lodash-es';
import { CaptureStatus, statusToTransition } from '../enums/capture-status.enum';
import { TitleCasePipe } from '@angular/common';

export class CaptureTransitionOption {
  constructor(
    public value: string,
    public display: string,
    public reasonOptions: CaptureTransitionReasonOption[] = []
  ) { }
}

const transitionOptions = [
  new CaptureTransitionOption(CaptureTransition.needsEvidence, 'Needs Evidence', [
    keyedReasonOptions[CaptureStatusReason.standardFaxOut],
    keyedReasonOptions[CaptureStatusReason.faxQueued],
    keyedReasonOptions[CaptureStatusReason.clientRequestedConsultNoteCollection],
    keyedReasonOptions[CaptureStatusReason.requestOlderConsultNote],
    keyedReasonOptions[CaptureStatusReason.requestMoreRecentConsultNote],
    keyedReasonOptions[CaptureStatusReason.drugNotReferencedRequestOlderConsultNote],
    keyedReasonOptions[CaptureStatusReason.drugNotReferencedRequestMoreRecentConsultNote],
    keyedReasonOptions[CaptureStatusReason.drugNotReferenced],
  ]),
  new CaptureTransitionOption(CaptureTransition.gatherEvidenceBlocked, 'Gather Evidence Blocked', [
    keyedReasonOptions[CaptureStatusReason.patientNotAtPractice],
    keyedReasonOptions[CaptureStatusReason.prescriberNotAtPractice],
    keyedReasonOptions[CaptureStatusReason.transmittedNoResponse],
    keyedReasonOptions[CaptureStatusReason.transmittedNoResponseToCustomNoteFax],
    keyedReasonOptions[CaptureStatusReason.faxFailed],
    keyedReasonOptions[CaptureStatusReason.faxReceivedHasAnIssue],
    keyedReasonOptions[CaptureStatusReason.needsMailingAddress],
    keyedReasonOptions[CaptureStatusReason.practiceWorkingOnRequest],
    keyedReasonOptions[CaptureStatusReason.noRecordForDateOfService],
    keyedReasonOptions[CaptureStatusReason.additionalInformationRequested],
    keyedReasonOptions[CaptureStatusReason.needsManualReview],
    keyedReasonOptions[CaptureStatusReason.other],
  ]),
  new CaptureTransitionOption(CaptureTransition.needsReferralMatchApproval, 'Needs Referral Match Approval'),
  new CaptureTransitionOption(CaptureTransition.ceReview, 'CE Review', [
    keyedReasonOptions[CaptureStatusReason.additionalReferralDocumentationRequired],
    keyedReasonOptions[CaptureStatusReason.referralCollectionDeferred],
    keyedReasonOptions[CaptureStatusReason.prescriberIsCeProvider],
    keyedReasonOptions[CaptureStatusReason.drugNotReferencedOnConsultNote],
    keyedReasonOptions[CaptureStatusReason.emrConsultNoteReviewRequired],
  ]),
  new CaptureTransitionOption(CaptureTransition.internalReview, 'Internal Review', [
    keyedReasonOptions[CaptureStatusReason.requiresReviewDrug],
    keyedReasonOptions[CaptureStatusReason.questionOnPatientMatch],
    keyedReasonOptions[CaptureStatusReason.questionOnReferralMatch],
    keyedReasonOptions[CaptureStatusReason.questionOnConsultNote],
    keyedReasonOptions[CaptureStatusReason.alreadyRequestedCEConsultNoteReview],
    keyedReasonOptions[CaptureStatusReason.potentialDataFeedIssue],
    keyedReasonOptions[CaptureStatusReason.missingInfo],
    keyedReasonOptions[CaptureStatusReason.ceHasConsultNote],
    keyedReasonOptions[CaptureStatusReason.needsPayment],
    keyedReasonOptions[CaptureStatusReason.practiceRequiresPatientRelease],
  ]),
  new CaptureTransitionOption(CaptureTransition.reject, 'Reject', [
    keyedReasonOptions[CaptureStatusReason.invalidPatientFirstNameMatch],
    keyedReasonOptions[CaptureStatusReason.invalidPatientLastNameMatch],
    keyedReasonOptions[CaptureStatusReason.notCePatient],
    keyedReasonOptions[CaptureStatusReason.serviceDateBeforeFirstInteraction],
    keyedReasonOptions[CaptureStatusReason.drugNotReferencedOnConsultNote],
    keyedReasonOptions[CaptureStatusReason.referralNotBeforeDrugWrittenDate],
    keyedReasonOptions[CaptureStatusReason.notAValidPrescriberForClient],
    keyedReasonOptions[CaptureStatusReason.prescriberSpecialtyDoesNotReceiveReferrals],
    keyedReasonOptions[CaptureStatusReason.drugValueIsTooLow],
    keyedReasonOptions[CaptureStatusReason.expired],
    keyedReasonOptions[CaptureStatusReason.patientMarkedInactive],
    keyedReasonOptions[CaptureStatusReason.duplicateClaimVerified],
    keyedReasonOptions[CaptureStatusReason.espExpired],
    keyedReasonOptions[CaptureStatusReason.notAValidCePrescriptionInternalConsultant],
    keyedReasonOptions[CaptureStatusReason.psychologyProviderDrug],
    keyedReasonOptions[CaptureStatusReason.roiNotObtained],
    keyedReasonOptions[CaptureStatusReason.providerNotValid],
  ]),
  new CaptureTransitionOption(CaptureTransition.clientReject, 'Client Reject', [
    keyedReasonOptions[CaptureStatusReason.clientRejectedPatientPrescriber],
    keyedReasonOptions[CaptureStatusReason.clientRejectedPrescription],
    keyedReasonOptions[CaptureStatusReason.clientRejectedPrescriber],
    keyedReasonOptions[CaptureStatusReason.clientRejectedPatient],
    keyedReasonOptions[CaptureStatusReason.clientRejectedPrescriberPrescription],
  ]),
  new CaptureTransitionOption(CaptureTransition.readyToVerify, 'Ready To Verify'),
  new CaptureTransitionOption(CaptureTransition.verify, 'Verify'),
  new CaptureTransitionOption(CaptureTransition.verifyClientPrescriberList, 'Verify - Client Prescriber List'),
  new CaptureTransitionOption(CaptureTransition.discard, 'Discard'),
  new CaptureTransitionOption(CaptureTransition.reverse, 'Reverse', [
    keyedReasonOptions[CaptureStatusReason.notCePatient],
    keyedReasonOptions[CaptureStatusReason.noValidReferral],
    keyedReasonOptions[CaptureStatusReason.ineligibleManufacturer],
    keyedReasonOptions[CaptureStatusReason.ineligiblePrescriberSpecialty],
    keyedReasonOptions[CaptureStatusReason.incorrectAnswersInCeTaskIdentified],
    keyedReasonOptions[CaptureStatusReason.par8oReversal],
    keyedReasonOptions[CaptureStatusReason.claimPartnerReversal],
    keyedReasonOptions[CaptureStatusReason.claimPartnerRetraction],
    keyedReasonOptions[CaptureStatusReason.claimPartnerIneligible],
    keyedReasonOptions[CaptureStatusReason.other],
  ]),
];

const keyedTransitionOptions = keyBy(transitionOptions, 'value');

const reasonsForStatus = (status: CaptureStatus) => {
  const transition = statusToTransition[status];
  if (keyedTransitionOptions[transition]) {
    return keyedTransitionOptions[transition].reasonOptions;
  } else {
    return [];
  }
};

const displayCaptureStatus = (value: string): string => {
  if (value) {
    value = value.replace(new RegExp('_', 'g'), ' ');
    value = new TitleCasePipe().transform(value);
    value = value.replace('Ce', 'CE');
    value = value.replace('Client Prescription Internal Consultant', 'Client Prescription (Internal Consultant)');
    value = value.replace('Outreach Attempt Evidence', 'Uses Outreach Attempts');
    return value.replace('Verified ', 'Verified - ');
  } else {
    return value;
  }
}

export {
  transitionOptions,
  keyedTransitionOptions,
  reasonsForStatus,
  displayCaptureStatus
};
