import { Component, Input } from '@angular/core';
import { ReferralMatchVerification } from '../../../core/models/referral-match-verification.model';
import { MinimalProvider } from 'app/core/models/minimal-provider.model';

@Component({
  selector: 'app-validate-referral-match-verifications-client-list',
  templateUrl: './validate-referral-match-verifications-client-list.component.html',
  styleUrls: ['./validate-referral-match-verifications-client-list.component.scss'],
})
export class ValidateReferralMatchVerificationsClientListComponent {
  @Input() patientReferralMatchVerifications: ReferralMatchVerification[];
  @Input() provider: MinimalProvider;
}
