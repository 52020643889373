import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ProviderCallLog } from 'app/core/models/provider-call-log.model';
import { ProviderCallLogsService } from 'app/core/services/provider-call-logs.service';
import { Provider } from 'app/core/models/provider.model';
import { ProviderCallLogOutcome } from 'app/core/enums/provider-call-log-outcome.enum';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Office } from 'app/core/models/office.model';
import { ProviderService } from 'app/core/services/provider.service';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { OfficeService } from 'app/core/services/office.service';
import { sortBy } from 'lodash-es';

@Component({
  selector: 'app-provider-call-log-form',
  templateUrl: './provider-call-log-form.component.html',
  styleUrls: ['./provider-call-log-form.component.scss'],
})

export class ProviderCallLogFormComponent implements OnInit {
  // this component handles provider call logs based on the
  // input (provider or office).
  @Input() provider: Provider;
  @Input() office: Office;

  @Output() newProviderCallLog = new EventEmitter<ProviderCallLog>();

  providerCallLogOutcomes;
  offices: Office[];
  officeOptions;
  providers: Provider[];
  providerOptions;
  selectedCallOutcome: string;
  selectedOfficeFax: string;
  enteredPhone: string;
  notes: string;
  validationRequired = false;
  officeOrPhoneRequired = false;
  providerCallLogForm;
  submitting = false;

  constructor(
    private providerCallLogsService: ProviderCallLogsService,
    private providerService: ProviderService,
    private officeService: OfficeService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.loadOutcomes();
    this.loadOffices();
    this.loadProviders();
  }

  hasOutcome(): boolean {
    return this.selectedCallOutcome != null;
  }

  hasOfficeOrPhone(): boolean {
    return this.selectedOfficeFax != null || this.enteredPhone != null;
  }

  onBlur() {
    this.formValid();
  }

  onNotesBlur() {
    const notes = this.providerCallLogForm.get('notes')
    notes.setValue(notes.value.trim());
    this.formValid();
  }

  onSelectOutcome() {
    this.formValid()
  }

  onSelectOffice() {
    const office = Object.values(this.offices).find(o => o.fax === this.providerCallLogForm.get('selectedOfficeFax').value);
    if (office && office.phone) {
      this.providerCallLogForm.get('enteredPhone').setValue(this.formatPhone(office.phone));
    } else {
      this.providerCallLogForm.get('enteredPhone').setValue(null);
    }
    this.formValid();
  }

  onSelectProvider() {
    const enteredPhone = this.providerCallLogForm.get('enteredPhone');
    if (!enteredPhone.value) {
      enteredPhone.setValue(this.formatPhone(this.office.phone));
    }
    this.formValid();
  }

  onFormCancel() {
    this.initializeForm();
  }

  onFormSave() {
    this.validationRequired = true;
    const {
      selectedProviderNpi,
      selectedCallOutcome,
      selectedOfficeFax,
      enteredPhone,
      notes
    } = this.providerCallLogForm.value;

    if (this.formValid() && !this.submitting) {
      this.submitting = true;

      this.providerCallLogsService
        .create(
          selectedProviderNpi,
          selectedCallOutcome,
          selectedOfficeFax,
          enteredPhone,
          notes)
        .subscribe(
          (providerCallLog: ProviderCallLog) => {
            this.handleCallLogCreation(providerCallLog);
          }
        );
    }
  }

  private formValid() {
    const { selectedOfficeFax, enteredPhone, notes } = this.providerCallLogForm.value;
    if (selectedOfficeFax || enteredPhone) {
      this.officeOrPhoneRequired = false;
    } else {
      this.officeOrPhoneRequired = true;
    }

    if (this.providerCallLogForm.valid && !this.officeOrPhoneRequired && notes.trim().length > 0) {
      return true;
    } else {
      return false;
    }
  }

  private handleCallLogCreation(providerCallLog: ProviderCallLog) {
    this.validationRequired = false;
    this.officeOrPhoneRequired = false;
    this.submitting = false;
    this.initializeForm();
    this.newProviderCallLog.emit(providerCallLog);
  }

  private loadOutcomes() {
    this.providerCallLogOutcomes = Object.values(ProviderCallLogOutcome).map(v => ({
      value: v,
      display: v,
    }));
  }

  // offices are only loaded if a provider
  // was supplied as an input
  private loadOffices() {
    if (this.provider) {
      this.providerService.getProviderOffices(this.provider.id).subscribe((providerOffices: ProviderOffice[]) => {
        this.offices = providerOffices.map(po => po.office);
        this.officeOptions = this.offices.map(office => (
          { value: office.fax, display: this.officeName(office) }
        ));
        this.officeOptions.unshift({ value: null, display: "Unknown" });
      });
    }
  }

  // providers are only loaded if an office
  // was supplied as an input
  private loadProviders() {
    if (this.office) {
      this.officeService.getProviderOffices(this.office.id).subscribe((providerOffices: ProviderOffice[]) => {
        this.providers = sortBy(providerOffices.map(po => po.provider), 'fullName');
        this.providerOptions = this.providers.map(provider => (
          { value: provider.npi, display: this.providerName(provider) }
        ));
        this.providerOptions.unshift({ value: null, display: "Unknown" });
      });
    }
  }

  private officeName(office) {
    let officeName: string;
    if (office) {
      if (office.officeNames) {
        officeName = office.officeNames.join(', ');
      } else {
        officeName = office.address1;
      }
    } else {
      return 'unknown';
    }
    return (((officeName.length > 50) ? officeName.slice(0, 50 - 1) + '...' : officeName) + ` [F: ${this.formatPhone(office.fax)}]`);
  }

  private providerName(provider) {
    return `${provider.fullName} (NPI: ${provider.npi})`;
  }

  private formatPhone(phone) {
    const parsedNumber = parsePhoneNumber(phone);
    return parsedNumber ? parsedNumber.formatNational() : phone;
  }

  private initializeForm() {
    this.providerCallLogForm = this.formBuilder.group({
      selectedProviderNpi: [this.provider?.npi],
      selectedCallOutcome: [null, Validators.required],
      selectedOfficeFax: [this.office?.fax],
      enteredPhone: [null],
      notes: ['', Validators.required],
    });
  }

  get enteredPhoneInvalid(): boolean {
    return this.providerCallLogForm.get('enteredPhone').hasError('invalidNumber');
  }

  get selectedCallOutcomeRequired(): boolean {
    return this.providerCallLogForm.get('selectedCallOutcome').hasError('required');
  }

  get notesRequired(): boolean {
    return this.providerCallLogForm.get('notes').hasError('required');
  }

  invalidCss(controlName: string) {
    if (this.validationRequired) {
      return {
        'is-invalid': this.isControlInvalid(controlName) || this.officeOrPhoneRequired,
      };
    }
  }

  private isControlInvalid(controlName: string) {
    const control = this.providerCallLogForm.get(controlName);

    if (control) {
      return control.invalid;
    }
  }
}
