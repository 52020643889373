import { Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatchingPrescriberDrug } from 'app/core/models/matching-prescriber-drug.model';
import { WrittenDateWithInRangeStatus } from 'app/core/lib/verification-utils';

@Component({
  selector: 'app-validate-drug-match-details',
  templateUrl: './validate-drug-match-details.component.html',
  styleUrls: ['./validate-drug-match-details.component.scss'],
  providers: [DatePipe],
})
export class ValidateDrugMatchDetailsComponent {
  _matchingPrescriberDrug: MatchingPrescriberDrug;
  prescriberNames = [];
  encounterDates: string[] = [];

  @Input() checkedWrittenDates: WrittenDateWithInRangeStatus[];

  get matchingPrescriberDrug(): MatchingPrescriberDrug {
    return this._matchingPrescriberDrug;
  }

  @Input()
  set matchingPrescriberDrug(mpd: MatchingPrescriberDrug) {
    this._matchingPrescriberDrug = mpd;

    this.prescriberNames =
      this.matchingPrescriberDrug.relatedPrescribers.map(provider => provider.fullName);
  }
}
