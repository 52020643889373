<table class="table table-bordered table-hover">
  <thead class="table-dark">
    <tr>
      <th>CLAIM ID</th>
      <th>STATUS</th>
      <th *ngIf="displayClient">CLIENT</th>
      <th>SOURCE</th>
      <th class="text-center">ESTIMATED VALUE</th>
      <th class="text-center" *ngIf="internalConsultantView">PATIENT ESTIMATED VALUE</th>
      <th class="text-center" *ngIf="!internalConsultantView">MANUAL FAX</th>
      <th class="text-end">CREATED ON</th>
      <th class="text-end">EXPIRES ON</th>
      <th class="text-end" *ngIf="!internalConsultantView">VERIFIED ON</th>
      <th *ngIf="displayPatient">CE-PATIENT</th>
      <th>RX-PATIENT</th>
      <th *ngIf="displayProvider">PRESCRIBER</th>
      <th>DRUG</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let capture of captureQueueResults">
      <td>

        <a routerLink="/capture-admin/captures/{{capture.id}}" *ngIf="!openInNewTab">
          <span class="text-nowrap">{{ capture.candidate.claimIdentifier }}</span>
        </a>
        <a routerLink="/capture-admin/captures/{{capture.id}}" target="_blank" *ngIf="openInNewTab">
          <span class="text-nowrap">{{ capture.candidate.claimIdentifier }}</span>
        </a>
        <div *ngIf="capture.status === captureStatus.ceReview && capture.hasClientPrescriberTask">
          <span class="badge bg-success fw-normal rounded-0">Client Prescriber Task</span>
        </div>
        <div *ngIf="capture.status === captureStatus.ceReview && capture.hasClientReferralTask">
          <span class="badge bg-success fw-normal rounded-0">Client Referral Task</span>
        </div>
        <div>
          <app-user-presence category="capture" [targetId]="capture.id" />
        </div>
      </td>
      <td class="status-column">
        <strong>{{ capture.status | captureStatus }}</strong>
        <br />
        {{ capture.statusReason | captureStatusReason }}
      </td>
      <td *ngIf="displayClient">
        {{ capture.client.name }}
        <span *ngIf="capture.client.state">
          ({{ capture.client.state }})
        </span>
        <div *ngIf="!capture.client.active" class="inactive-client">
          INACTIVE
        </div>
      </td>
      <td>{{ claimSourceDisplay[capture.candidate.source.toLowerCase()] | blankValueDash }}</td>
      <td class="text-center">
        {{ capture.estimatedValueForDisplay }}
        <div class="mt-1 px-1 border">
          <strong>PENDING</strong>
          <div>{{ capture.pendingEstimatedValue | currency: 'USD':'symbol':'1.0-0' }}</div>
        </div>
        <div class="mt-1 px-1 border">
          <strong>WIP</strong>
          <div>{{ capture.wipEstimatedValue | currency: 'USD':'symbol':'1.0-0' }}</div>
        </div>
      </td>
      <td class="text-center" *ngIf="internalConsultantView">
        {{ capture.patientEstimatedValue | currency: 'USD':'symbol':'1.0-0' }}
      </td>
      <td class="text-center" *ngIf="!internalConsultantView">
        <div [ngClass]="{ 'fw-bold': capture.manualFaxSent }">{{ capture.manualFaxSent ? 'Yes' : 'No' }}</div>
        <div *ngIf="capture.manualFaxSent">{{ capture.mostRecentManualFaxSentAt | date: 'MM/dd/yyyy' }}</div>
      </td>
      <td class="text-end">{{ capture.createdAt | date: 'MM/dd/yyyy' }}</td>
      <td class="text-end">
        <app-expiration-date [expirationDate]="capture.expirationDate" />
      </td>
      <td class="text-end" *ngIf="!internalConsultantView">{{ capture.verifiedAt | date: 'MM/dd/yyyy' }}</td>
      <td *ngIf="displayPatient">
        <app-patient-name
          [patient]="capture.sourcePatient"
          [showAlternateNames]=false
          [canViewPatient]="true" />
      </td>
      <td><app-candidate-patient-name [candidate]="capture.candidate" /></td>
      <td *ngIf="displayProvider">
        <app-prescriber-name [provider]="capture.provider" [linkToProvider]="true" />
      </td>
      <td>
        <app-candidate-drug [candidate]="capture.candidate" />
        <div class="small"><strong>SOURCE:</strong> {{ capture.candidate.source }}</div>
      </td>
    </tr>
  </tbody>
</table>
