import { ProviderInformationCollectionDetails } from "./provider-information-collection-details.model";
import { MinimalProvider } from "./minimal-provider.model";

export class Provider extends MinimalProvider {
  first: string;
  last: string;
  credentials: string;
  captureClientNames: string[];
  captureClientStates: string[];
  affiliatedClientNames: string[];
  specialties: string[];
  eligibleEncountersCount: number;
  ineligibleEncountersCount: number;
  confirmed: boolean;
  updatedAt: string;
  note: string;
  informationCollectionDetails?: ProviderInformationCollectionDetails;
}
