<ng-container *ngIf="!refreshing; else: spinner">
  <div *ngIf="outboundFaxes.length > 0; else: noFaxes">
    <div class="m-2">
      <app-pagination-header [paging]="paging" [label]="'Outbound Faxes'" (pageSizeChange)="onPageSizeChange($event)" />
    </div>
    <div class="m-2" *ngIf="!refreshing">
      <table class="table table-bordered table-sm">
        <thead class="table-dark">
          <tr>
            <th>DATE FAXED</th>
            <th>PATIENT</th>
            <th>PRESCRIBER</th>
            <th>SPECIALTY</th>
            <th>OFFICE</th>
            <th>FAX NUMBER</th>
            <th class="text-center">OUTBOUND STATUS</th>
            <th>CUSTOM TEXT</th>
            <th>CONSULT RECEIVED?</th>
            <th>CALL RECEIVED?</th>
            <th>PROVIDER FAX STATUS</th>
            <th>FAX TYPE</th>
            <th>SENT BY</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let outboundFax of outboundFaxes; trackBy: trackItemsBy">
            <td>{{ outboundFax.createdAt | date: 'M/d/yyyy' }}</td>
            <td>
              <app-patient-name [patient]="outboundFax.patient" [showAlternateNames]=false />
            </td>
            <td><app-prescriber-name [provider]="outboundFax.provider" /></td>
            <td>{{ outboundFax.provider.displaySpecialty }}</td>
            <td>{{ outboundFax.prescriberOfficesNames && outboundFax.prescriberOfficesNames.join(', ') }}</td>
            <td>
              <app-phone-number [number]="outboundFax.fax" />
              <app-copy-to-clipboard [text]="outboundFax.fax | phoneNumber" />
            </td>
            <td class="text-center">
              <div [ngSwitch]="outboundFax.status">
                <span *ngSwitchCase="outboundFaxStatuses.queued"> Sending... </span>
                <span *ngSwitchCase="outboundFaxStatuses.pendingScheduling"> Pending Scheduling </span>
                <span *ngSwitchCase="outboundFaxStatuses.failed">
                  <div>Failed</div>
                  <div *ngIf="outboundFax.mostRecentFailureMessage">{{ outboundFax.mostRecentFailureMessage }}</div>
                </span>
                <span *ngSwitchCase="outboundFaxStatuses.skipped">
                  <div>Skipped</div>
                  <div *ngIf="outboundFax.skipMessage">Reason: {{ outboundFax.skipMessage }}</div>
                </span>
                <span *ngSwitchCase="outboundFaxStatuses.scheduled">
                  <div>Scheduled to send after {{ outboundFax.scheduledTime | date: 'M/dd h:mm a' }}</div>
                  <div *ngIf="outboundFax.attemptCount > 0">
                    {{ outboundFax.attemptCount }} failed send attempt{{ outboundFax.attemptCount > 1 ? 's' : '' }}
                  </div>
                </span>
                <span *ngSwitchCase="outboundFaxStatuses.canceled">
                  <div>Canceled</div>
                  <small>user: {{ outboundFax.canceledByUser.username }}</small>
                </span>
                <span *ngSwitchDefault> {{ outboundFax.status | titlecase }} </span>
              </div>
              <app-outbound-fax-template [outboundFax]="outboundFax" />
              <div *ngIf="outboundFax.status === outboundFaxStatuses.scheduled" class="mt-1 px-1">
                <a href="" (click)="cancel($event, outboundFax)" class="text-danger">Cancel Fax</a>
              </div>
            </td>
            <td>{{ outboundFax.customNote }}</td>
            <td><app-consult-note-received [outboundFax]="outboundFax" /></td>
            <td><app-inbound-call-received [outboundFax]="outboundFax" /></td>
            <td>{{ keyedProviderOfficeStatusOptions[outboundFax.providerOfficeStatus]?.display }}</td>
            <td>{{ outboundFax.faxType }}</td>
            <td>{{ outboundFax.sentBy }}</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-2">
        <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
      </div>
    </div>
  </div>
  <ng-template #noFaxes><p class="ms-2">No provider faxes.</p></ng-template>
</ng-container>
<ng-template #spinner> <mat-spinner /> </ng-template>

