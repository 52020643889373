<div *ngIf="showSection">

  <hr class="mb-2" />

  <div class="row" *ngIf="!showAddOutreachAttemptEvidenceForm">
    <div class="col-4">
      <div class="row">
        <div class="col-6">
          <strong>Outreach Attempt Evidence</strong>
        </div>
        <div class="col-6 add-outreach-attempt-evidence" *ngIf="!outreachAttemptEvidence">
          <a href="javascript:;" (click)="onAddOutReachAttemptEvidenceClick()">
            Use Outreach Attempt Evidence
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="showAddOutreachAttemptEvidenceForm">
    <div class="col-8">
      <h4>Outreach Attempt Evidence</h4>

      <app-outreach-attempt-evidence-form
        [capture]="capture"
        (newPatientAttachment)="onNewPatientAttachment($event)"
        (canceled)="onAddOutReachAttemptEvidenceCanceled()" />
    </div>
  </div>
</div>

<div class="row" *ngIf="outreachAttemptEvidence">
  <div class="col-8">
    <app-outreach-attempt-evidence
      [outreachAttemptEvidence]="outreachAttemptEvidence"
      [provider]="capture.provider"
      [patientPrescriberId]="capture.patientPrescriberId" />
  </div>
</div>
