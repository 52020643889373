<div class="row">
  <div class="col-10">
    <h3>Providers</h3>

    <form novalidate>
      <div class="row flex-row">
        <div class="col">
          <div class="mb-3">
            <label for="firstName"><strong>First Name</strong></label>
            <input
              id="firstName"
              class="form-control"
              name="firstName"
              placeholder="Filter By First Name"
              [(ngModel)]="filters.firstName"
              (input)="onTextFilterChange()">
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="lastName"><strong>Last Name</strong></label>
            <input
              id="lastName"
              class="form-control"
              name="lastName"
              placeholder="Filter By Last Name"
              [(ngModel)]="filters.lastName"
              (input)="onTextFilterChange()">
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="npi"><strong>NPI</strong></label>
            <input
              id="npi"
              class="form-control"
              name="npi"
              placeholder="Filter By NPI"
              [(ngModel)]="filters.npi"
              (input)="onTextFilterChange()">
          </div>
        </div>
      </div>
      <div class="row flex-row mt-2">
        <div class="col">
          <div class="mb-3">
            <label for="city"><strong>City</strong></label>
            <input
              id="city"
              class="form-control"
              name="city"
              placeholder="Filter By City"
              [(ngModel)]="filters.city"
              (input)="onTextFilterChange()">
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="state"><strong>State</strong></label>
            <ng-select
              bindValue="value"
              bindLabel="display"
              [(ngModel)]="filters.state"
              [items]="stateOptions"
              placeholder="Filter By State"
              name="state"
              (change)="onFilterChange()" />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="status"><strong>Status</strong></label>
            <ng-select
              bindValue="value"
              bindLabel="display"
              [(ngModel)]="filters.status"
              [items]="providerStatusOptions"
              name="status"
              [multiple]="true"
              placeholder="Filter By Status"
              (remove)="onFilterChange()"
              (change)="onFilterChange()" />
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="col-2 page-actions">
    <button type="submit" class="btn btn-primary" (click)="onAddProviderClick($event)">Add Provider</button>
  </div>
</div>

<div class="mt-2" *ngIf="!loading; else: spinner">
  <app-pagination-header
    [paging]="paging"
    [label]="'providers'"
    (pageSizeChange)="onPageSizeChange($event)"
    [includeResetFiltersButton]="true"
    (resetFiltersClick)="onResetFiltersClick()" />
  <div class="mt-2" *ngIf="providers.length > 0; else: noProviders">
    <table class="table table-bordered table-hover">
      <thead class="table-dark">
        <tr>
          <th>NPI</th>
          <th>NAME</th>
          <th>SPECIALTIES</th>
          <th>STATES</th>
          <th>CLIENTS</th>
          <th>REVIEW STATUS</th>
          <th>UPDATED</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let provider of providers">
          <td>
            <a
              routerLink="/capture-admin/providers/{{provider.npi}}"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }">
              {{ provider.npi }}
              <app-copy-to-clipboard [text]="provider.npi" />
            </a>
          </td>
          <td>
            <div *ngIf="provider.fullName">
              {{ provider.fullName }}
              <app-copy-to-clipboard [text]="provider.fullName" />
            </div>
            <app-blank-value-dash *ngIf="!provider.fullName" />
          </td>
          <td>
            {{ provider.specialties.join(', ') | blankValueDash }}
          </td>
          <td>
            {{ provider.captureClientStates?.join(', ') | blankValueDash }}
          </td>
          <td>
            {{ provider.captureClientNames?.join(', ') | blankValueDash }}
          </td>
          <td>
            {{ keyedStatusOptions[provider.status].display }}
          </td>
          <td>
            {{ provider.updatedAt | date: 'MM/dd/yyyy' }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2">
      <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
    </div>
  </div>
</div>

<ng-template #spinner>
  <div *ngIf="loading" class="ms-1"><mat-spinner diameter="30" /></div>
</ng-template>

<ng-template #noProviders><p class="ms-1">No providers.</p></ng-template>
