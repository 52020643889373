export enum CaptureTransition {
  newStatus = 'new',
  missingPatientInteraction = 'missing_patient_interaction',
  needsPatientMatchApproval = 'needs_patient_match_approval',
  needsReferralMatchApproval = 'needs_referral_match_approval',
  needsNewConsultNote = 'needs_new_consult_note',
  needsEvidence = 'needs_evidence',
  pending = 'pending',
  gatherEvidenceBlocked = 'gather_evidence_blocked',
  internalReview = 'internal_review',
  ceReview = 'ce_review',
  onlyNeedsPatientInteraction = 'only_needs_patient_interaction',
  reject = 'reject',
  verify = 'verify',
  verifyClientPrescriptionInternalConsultant = 'verify_client_prescription_internal_consultant',
  verifyClientPrescriberList = 'verify_client_prescriber_list',
  discard = 'discard',
  reverse = 'reverse',
  clientReject = 'client_reject',
  readyToVerify = 'ready_to_verify',
}
