import { Injectable } from '@angular/core';

@Injectable()
export class DownloadService {
  download(url: string, filename: string = null) {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    if (filename) {
      link.setAttribute('download', filename);
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
