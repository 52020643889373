import { Component, Input, OnInit } from '@angular/core';
import { keyedStatusOptions } from 'app/core/options/provider-status.opts';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { MinimalProvider } from 'app/core/models/minimal-provider.model';

@Component({
  selector: 'app-prescriber-name',
  templateUrl: './prescriber-name.component.html',
  styleUrls: ['./prescriber-name.component.scss'],
})
export class PrescriberNameComponent implements OnInit {
  @Input() provider: MinimalProvider;
  @Input() linkToProvider = false;
  @Input() showSpecialtiesDetails = false;

  keyedStatusOptions = keyedStatusOptions;
  canViewProvider = false;

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
    this.canViewProvider = this.authService.isCaptureAdminUser;
  }

  get hasName() {
    return this.provider.fullName.trim().length > 0;
  }

  get hasSpecialtiesInfo() {
    return this.showSpecialtiesDetails && this.provider.displaySpecialty.trim().length > 0;
  }
}
