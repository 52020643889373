<div *ngIf="!loading; else: spinner">
  <div *ngIf="providerOffices.length === 0">There are no providers for this office.</div>

  <ng-container *ngIf="providerOffices.length > 0">
    <table class="table w-100">
      <thead class="table-dark">
        <tr>
          <th>ID</th>
          <th>NPI</th>
          <th class="text-nowrap">Provider Name</th>
          <th>Specialties</th>
          <th>Provider Fax Status</th>
          <th>Association Note</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let providerOffice of providerOffices">
          <td>{{ providerOffice.provider.id }}</td>
          <td>
            <a
            routerLink="/capture-admin/providers/{{providerOffice.provider.npi}}"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            target="_blank">
              {{ providerOffice.provider.npi }}
              <app-copy-to-clipboard [text]="providerOffice.provider.npi" />
            </a>
          </td>
          <td>{{ providerOffice.provider.fullName }}</td>
          <td>
            {{ providerOffice.provider.displaySpecialty || '--' }}
          </td>
          <td class="status-column">
            <app-provider-office-fax-status [providerOffice]="providerOffice" />
          </td>
          <td class="small notes-column">
            <app-notes-for-model
              [model]="providerOffice"
              [modelType]="'ProviderOffice'"
              [minimizeControls]="true"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

</div>

<ng-template #spinner> <mat-spinner /> </ng-template>
