import { Component, Input, OnInit } from '@angular/core';
import { MinimalProvider } from 'app/core/models/minimal-provider.model';
import { ProviderOffice } from '../../../core/models/provider-office.model';
import { ProviderOfficeFilters, ProviderService } from '../../../core/services/provider.service';

@Component({
  selector: 'app-prescriber-offices-scrollable',
  templateUrl: './prescriber-offices-scrollable.component.html',
  styleUrls: ['./prescriber-offices-scrollable.component.scss'],
})
export class PrescriberOfficesScrollableComponent implements OnInit {
  @Input() provider: MinimalProvider;
  @Input() acceptableFaxStatusOnly = false;

  officesRefreshing = true;
  providerOffices: ProviderOffice[] = [];
  providerOfficeFaxStatuses = {};

  constructor(private providerService: ProviderService) { }

  ngOnInit() {
    this.loadOffices();
  }

  loadOffices() {
    this.officesRefreshing = true;

    const filters = new ProviderOfficeFilters();
    filters.acceptableFaxStatusOnly = this.acceptableFaxStatusOnly;

    this.providerService
      .getProviderOffices(this.provider.id, filters)
      .subscribe((providerOffices: ProviderOffice[]) => {
        this.providerOffices = providerOffices;

        this.officesRefreshing = false;
      });
  }
}
