<ng-container *ngIf="!loading; else: spinner">
  <app-provider-header [provider]="provider" />

  <div class="page-container">
    <h6 class="card-title m-0 px-2 py-1 d-flex">
      <mat-icon>event</mat-icon>
      <div class="ms-1 pt-1">Provider Encounters</div>
    </h6>

    <div class="mt-3">
      <app-encounter-list
      [fetchEncounters]="fetchProviderEncounters"
      [eligibleCount]="provider.eligibleEncountersCount"
      [ineligibleCount]="provider.ineligibleEncountersCount"
    />
    </div>
  </div>
</ng-container>
<ng-template #spinner> <mat-spinner /> </ng-template>
