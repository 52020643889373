<form>
  <h5 class="question">
    Do you have a referral OR any other supporting documentation on file in the EHR for the patient
    <strong>{{ task.patient.fullName }}, DOB {{ task.patient.dob | date: 'M/dd/yyyy' }}</strong> for the prescriber
    <strong>{{ task.provider.fullName }}</strong> <u>OR</u> the prescriber’s office <u>OR</u> the prescriber’s
    specialty?
  </h5>
  <ng-container *ngFor="let choiceOption of choiceOptions">
    <div class="choice border bg-white rounded-0 px-3 py-2 my-1 mx-0" [ngClass]="{ disabled: disabled }">
      <div class="form-check" (click)="onChoiceClick($event, choiceOption.value)">
        <input
          [id]="choiceOption.value"
          [disabled]="disabled"
          name="selection"
          type="radio"
          [value]="choiceOption.value"
          [(ngModel)]="formModel.choice"
        />
        <label [for]="choiceOption.value" class="form-check-label">
          <strong>{{ choiceOption.label }}</strong>
        </label>
        <p class="form-text"><small [innerHTML]="choiceOption.helpText"></small></p>
      </div>
      <ng-container [ngSwitch]="choiceOption.value">
        <ng-container *ngSwitchCase="choices.yes">
          <div class="form-check mb-2 ps-5" *ngFor="let reason of choiceOption.reasons">
            <input
              [id]="reason"
              [name]="reason"
              class="form-check-input"
              type="checkbox"
              [disabled]="disabled"
              (change)="onVerificationReasonChange($event, choiceOption.value, reason)"
              [(ngModel)]="formModel.reasonSelections[reason]"
              value="reasonOption.value.value" />

            <label [for]="reason" class="form-check-label">
              <app-client-referral-task-option-reason
                [patient]="task.patient"
                [provider]="task.provider"
                [reason]="reason" />
            </label>

            <app-client-referral-task-option-reason-additional-fields
              *ngIf="formModel.reasonSelections[reason]"
              [reason]="reason"
              [model]="formModel.reasonAttributes[reason]"
              (modelChange)="onAdditionalFieldsChange()"
              [disabled]="disabled" />
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="choices.no">
          <app-client-task-rejection-reasons
            [task]="task"
            [reasonSelections]="formModel.reasonSelections"
            [formDisabled]="formDisabled"
            (rejectionReasonsChange)="onRejectionReasonChange($event, choiceOption.value)"
            (rejectionReasonsClear)="onRejectionReasonsClear()"
          />
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</form>
