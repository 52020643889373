import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { keyBy } from 'lodash-es';
import { Capture } from "app/core/models/capture.model";
import { PatientPrescriber } from "app/core/models/patient-prescriber.model";
import { ClientReferralMatchOption } from "app/core/models/client-referral-match-option.model";
import { AuthenticationService } from "app/core/services/authentication.service";
import { ClientReferralVerificationsService, PagedClientReferralVerifications } from "app/core/services/client-referral-verifications.service";
import {
  isClientReferralMatchVerification,
  getAllSelectedReferralMatchVerifications,
  isReferralMatchVerificationDisabled,
  resolveClientVerification
} from 'app/core/lib/verification-utils';
import { ReferralMatchVerification } from "app/core/models/referral-match-verification.model";
import { ClientReferralVerification } from "app/core/models/client-referral-verification.model";
import { ReferralMatchVerificationEvent } from "app/core/models/referral-match-verification-event.model";

@Component({ template: '' })
export abstract class ReferralMatchVerificationsClientBaseComponent implements OnInit {
  @Input() capture: Capture;
  @Input() patientPrescriber: PatientPrescriber;
  @Input() verificationChangeInProgress = false;

  @Output() verificationChange = new EventEmitter<ReferralMatchVerificationEvent>();

  referralMatchOptions: ClientReferralMatchOption[] = [];
  patientPrescriberChangedSubscription = null;
  loadingReferralMatchOptions = true;

  constructor(
    protected authService: AuthenticationService,
    protected clientReferralVerificationsService: ClientReferralVerificationsService,
  ) { }

  ngOnInit() {
    this.fetchClientReferralVerifications();
  }

  fetchClientReferralVerifications() {
    const filters = {
      patientId: this.patientPrescriber.patientId,
      hasClientReferralTaskSubmission: true,
      includeClientReferralTaskSubmission: true
    };

    this.clientReferralVerificationsService.getList(filters).
      subscribe(({ clientReferralVerifications }: PagedClientReferralVerifications) => {
        this.setupReferralMatchOptions(clientReferralVerifications);
        this.loadingReferralMatchOptions = false;
      });
  }

  setupReferralMatchOptions(clientReferralVerifications: ClientReferralVerification[]) {
    const filteredRmvs = this.patientPrescriber.referralMatchVerifications.filter(isClientReferralMatchVerification);
    const rmvsByCrvId = keyBy(filteredRmvs, (rmv: ReferralMatchVerification) => rmv.clientReferralVerificationId);
    const allSelectedRmvs = getAllSelectedReferralMatchVerifications(this.patientPrescriber);

    this.referralMatchOptions =
      clientReferralVerifications.map(clientReferralVerification => {
        const resolvedVerification =
          resolveClientVerification(clientReferralVerification, rmvsByCrvId);

        return new ClientReferralMatchOption(
          resolvedVerification,
          clientReferralVerification,
          !!resolvedVerification.id,
          this.isReferralMatchVerificationDisabledOrFormDisabled(allSelectedRmvs, resolvedVerification)
        )
      });
  }

  refreshReferralMatchOptions() {
    const allSelectedRmvs = getAllSelectedReferralMatchVerifications(this.patientPrescriber);

    this.referralMatchOptions.forEach(option => {
      option.disabled = this.isReferralMatchVerificationDisabledOrFormDisabled(allSelectedRmvs, option.verification);
    });
  }

  isReferralMatchVerificationDisabledOrFormDisabled(
    allSelectedRmvs: ReferralMatchVerification[],
    referralMatchVerification: ReferralMatchVerification
  ) {
    return this.verificationChangeInProgress ||
      isReferralMatchVerificationDisabled(
        referralMatchVerification,
        this.capture,
        allSelectedRmvs,
        this.authService
      );
  }

}
