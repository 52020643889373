import { Component, Input } from '@angular/core';
import { Patient } from 'app/core/models/patient.model';

@Component({
  selector: 'app-patient-header',
  templateUrl: './patient-header.component.html',
  styleUrls: ['./patient-header.component.scss'],
})
export class PatientHeaderComponent {
  @Input() patient: Patient;

  public get active(): boolean {
    return this.patient && this.patient.inactiveDate === null;
  }

  onPatientDeactivated(patient: Patient) {
    location.reload();
  }
}
