import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Patient } from 'app/core/models/patient.model';
import { ClientReferralVerificationsService, PagedClientReferralVerifications } from "app/core/services/client-referral-verifications.service";
import { EhrReferralVerificationsService } from 'app/core/services/ehr-referral-verifications.service';
import { PatientService, ReferralsResult } from 'app/core/services/patient.service';
import { Referral } from 'app/core/models/referral.model';
import { ClientReferralVerification } from 'app/core/models/client-referral-verification.model';
import { EhrReferralVerification } from 'app/core/models/ehr-referral-verification.model';

@Component({
  selector: 'app-capture-admin-patient-referrals',
  templateUrl: './capture-admin-patient-referrals.component.html',
  styleUrls: ['./capture-admin-patient-referrals.component.scss'],
})
export class CaptureAdminPatientReferralsComponent implements OnInit {
  patient: Patient;
  loading: boolean;

  loadingReferrals = true;
  referrals: Referral[] = [];
  ineligibleReferrals: Referral[] = [];
  showIneligibleReferrals = false;

  loadingClientReferralVerifications = true;
  clientReferralVerifications: ClientReferralVerification[] = [];

  loadingEhrReferralVerifications = true;
  ehrReferralVerifications: EhrReferralVerification[] = [];

  constructor(
    private patientService: PatientService,
    private clientReferralVerificationsService: ClientReferralVerificationsService,
    private ehrReferralVerificationsService: EhrReferralVerificationsService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    const patientId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.loading = true;
    this.patientService.get(patientId).subscribe(patient => {
      this.patient = patient;
      this.loading = false;

      this.fetchReferrals(patientId);
      this.fetchClientReferralVerifications(patientId);
      this.fetchEhrReferralVerifications(patientId);
    });
  }

  private fetchReferrals(patientId: number) {
    this.patientService.getReferrals(patientId).subscribe((result: ReferralsResult) => {
      if (result.meta.eligibilityBasedOnLocation) {
        this.showIneligibleReferrals = true;

        this.referrals =
          result.referrals.filter(referral => referral.locationEligibleDuringServiceDate);

        this.ineligibleReferrals =
          result.referrals.filter(referral => !referral.locationEligibleDuringServiceDate);
      } else {
        this.showIneligibleReferrals = false;
        this.referrals = result.referrals;
      }

      this.loadingReferrals = false;
    });
  }

  private fetchClientReferralVerifications(patientId: number) {
    const filters = {
      patientId,
      hasClientReferralTaskSubmission: true,
      includeClientReferralTaskSubmission: true
    };

    this.clientReferralVerificationsService.getList(filters).
      subscribe(({ clientReferralVerifications }: PagedClientReferralVerifications) => {
        this.clientReferralVerifications = clientReferralVerifications;
        this.loadingClientReferralVerifications = false;
      });
  }

  private fetchEhrReferralVerifications(patientId: number) {
    const filters = { patientId };
    this.ehrReferralVerificationsService.getList(filters).subscribe(result => {
      this.ehrReferralVerifications = result.ehrReferralVerifications;
      this.loadingEhrReferralVerifications = false;
    });
  }
}
