<ng-container *ngIf="!loading; else: spinner">
  <app-patient-header [patient]="patient" />

  <div class="page-container">
    <div class="mt-3">

      <div class="card">
        <div class="card-body">
          <h5 class="mb-4">Patient Referrals From EHR Feeds</h5>

          <ng-container *ngIf="!loadingReferrals; else: spinnerSmall">
            <app-referrals-standard-listing
              *ngIf="referrals.length > 0 || ineligibleReferrals.length > 0; else: noReferrals"
              [dark]="true"
              [eligibleReferrals]="referrals"
              [ineligibleReferrals]="ineligibleReferrals"
              [showIneligibleReferrals]="showIneligibleReferrals"
              [compactClientView]="false" />
          </ng-container>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <div class="card">
        <div class="card-body">
          <h5 class="mb-4">Referral Evidence Found in EHR</h5>

          <ng-container *ngIf="!loadingEhrReferralVerifications; else: spinnerSmall">
            <app-referrals-ehr-listing
              *ngIf="ehrReferralVerifications.length > 0; else: noReferrals"
              [dark]="true"
              [ehrReferralVerifications]="ehrReferralVerifications"
              [compactClientView]="false" />
          </ng-container>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <div class="card">
        <div class="card-body">
          <h5 class="mb-4">Patient Referrals From Client Tasks</h5>

          <ng-container *ngIf="!loadingClientReferralVerifications; else: spinnerSmall">
            <app-referrals-client-listing
              *ngIf="clientReferralVerifications.length > 0; else: noReferrals"
              [dark]="true"
              [clientReferralVerifications]="clientReferralVerifications"
              [compactClientView]="false" />
          </ng-container>
        </div>
      </div>
    </div>

  </div>
</ng-container>
<ng-template #spinner> <mat-spinner /> </ng-template>
<ng-template #spinnerSmall> <mat-spinner diameter="30" /> </ng-template>
<ng-template #noReferrals> No referrals of this type found. </ng-template>
