import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ClientTaskRejectionReason } from '../../../core/enums/client-task-rejection-reason.enum';
import { Patient } from '../../../core/models/patient.model';
import { anyValueSelected } from '../../../core/lib/enum-utils';
import { handleSimpleChanges } from '../../../core/lib/component-utils';
import { MinimalProvider } from 'app/core/models/minimal-provider.model';

@Component({
  selector: 'app-client-task-rejection-reasons',
  templateUrl: './client-task-rejection-reasons.component.html',
  styleUrls: ['./client-task-rejection-reasons.component.scss'],
})
export class ClientTaskRejectionReasonsComponent implements OnInit, OnChanges {
  reasons = ClientTaskRejectionReason;
  reasonValues: ClientTaskRejectionReason[] = Object.values(this.reasons);
  anyValuesSelected = false;
  showRejectionReasons = false;
  disabled = false;

  @Input() task: { patient: Patient; provider: MinimalProvider, frozen: boolean };
  @Input() reasonSelections: Record<string, unknown> = {};
  @Input() formDisabled: boolean;
  @Output() rejectionReasonsChange = new EventEmitter<{ reason: ClientTaskRejectionReason; checked: boolean }>();
  @Output() rejectionReasonsClear = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.anyValuesSelected = anyValueSelected(this.reasonSelections, ClientTaskRejectionReason);
    this.showRejectionReasons = this.anyValuesSelected;

    this.disabled = this.task.frozen || this.formDisabled;
  }

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string) => {
      if (inputName === 'reasonSelections') {
        this.anyValuesSelected = anyValueSelected(this.reasonSelections, ClientTaskRejectionReason);
      }
    });
  }

  onShowRejectionReasonsClick($event) {
    $event.preventDefault();

    if (!anyValueSelected(this.reasonSelections, ClientTaskRejectionReason)) {
      this.showRejectionReasons = !this.showRejectionReasons;
    }
  }

  onReasonChange($event, reason: ClientTaskRejectionReason) {
    const input = $event.target as HTMLInputElement;
    this.rejectionReasonsChange.emit({ reason, checked: input.checked });
  }

  onClearClick($event) {
    $event.preventDefault();
    this.rejectionReasonsClear.emit();
  }
}
