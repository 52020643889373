<div class="card mt-1">
  <div class="card-body">
    <div class="mb-2">
      <div><h4>E-Prescriber Matches</h4></div>
      <div class="claim-prescription table-light border p-2 mb-3">
        <h5 class="my-2">This Prescription</h5>

        <table class="table">
          <thead>
            <th>RX-DRUG DESCRIPTION</th>
            <th>RX-NDC</th>
            <th>RX-PRESCRIBER</th>
            <th>WRITTEN DATE</th>
            <th>RX-PATIENT</th>
          </thead>
          <tbody>
            <tr>
              <td>{{ claimPrescriptionDrugDescriptions }}</td>
              <td>{{ claimPrescriptionDrugNdcs }}</td>
              <td>
                {{ capture.provider.fullName }}
                <div>
                  NPI: {{ capture.prescriber.npi }}
                </div>
              </td>
              <td>{{ capture.prescription.writtenDate | date: 'MM/dd/yyyy' }}</td>
              <td>
                {{ capture.patient.fullName }}
                <div>
                  DOB: {{ capture.patient.dob | date: 'MM/dd/yyyy' }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="client-prescription-matches">
        <h5 class="my-2">Client E-Prescribe Matches</h5>
        <table class="table">
          <thead>
            <th>EHR PRESCRIPTION ID</th>
            <th>PATIENT</th>
            <th>CE-ORDERING-PRESCRIBER</th>
            <th>CE-AUTHORIZING-PRESCRIBER</th>
            <th>WRITTEN DATE</th>
            <th>WRITTEN LOCATION</th>
            <th>MRN</th>
            <th>EHR INSTANCE</th>
            <th>MATCH TYPE</th>
          </thead>
          <tbody>
            <tr *ngFor="let option of clientPrescriptionMatchOptions"
                [ngClass]="{ 'best-match': option.bestMatch }">
              <td>{{ option.match.clientPrescription.ehrIdentifier }}</td>
              <td>
                {{ option.match.clientPrescription.patient.fullName }}
              </td>
              <td>
                {{ option.match.clientPrescription.orderingProvider.fullName }}
                <div>
                  NPI: {{ option.match.clientPrescription.orderingProvider.npi }}
                </div>
              </td>
              <td>
                {{ option.match.clientPrescription.authorizingProvider.fullName }}
                <div>
                  NPI: {{ option.match.clientPrescription.authorizingProvider.npi }}
                </div>
              </td>
              <td>{{ option.match.clientPrescription.writtenDate | date: 'MM/dd/yyyy' }}</td>
              <td>
                {{ option.match.clientPrescription.locationName }}
                <div *ngIf="option.match.clientPrescription.locationEligibleDuringServiceDate !== null">
                  <div class="location-eligibility-indicator location-eligible"
                       *ngIf="option.match.clientPrescription.locationEligibleDuringServiceDate">
                    Eligible
                  </div>
                  <div class="location-eligibility-indicator location-ineligible"
                       *ngIf="!option.match.clientPrescription.locationEligibleDuringServiceDate">
                    Ineligible
                  </div>
                </div>
              </td>
              <td>{{ option.match.clientPrescription.sourcePatientMrn }}</td>
              <td>{{ option.match.clientPrescription.ehrInstance }}</td>
              <td class="client-prescriber-match-kind">
                {{ option.match.kind | clientPrescriptionMatchKindName }}
              </td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="clientPrescriptionMatchOptions.length === 0"
             class="no-matches-message">
          No client e-prescribe matches found.
        </div>
      </div>
    </div>
  </div>
</div>
