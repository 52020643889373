import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Referral } from 'app/core/models/referral.model';
import { ReferralMatchVerification } from 'app/core/models/referral-match-verification.model';
import { ReferralMatchOption } from 'app/core/models/referral-match-option.model';

type ListingIterable = ReferralMatchOption | Referral;

@Component({
  selector: 'app-referrals-standard-listing',
  templateUrl: './referrals-standard-listing.component.html',
  styleUrls: ['./referrals-standard-listing.component.scss'],
})
export class ReferralsStandardListingComponent {
  @Input() dark = false;
  @Input() loadingReferrals = false;
  @Input() eligibleReferrals: Referral[];
  @Input() ineligibleReferrals: Referral[];
  @Input() referralMatchOptions: ReferralMatchOption[];
  @Input() showIneligibleReferrals = false;
  @Input() compactClientView = false;

  @Output() referralVerificationChange = new EventEmitter<ReferralMatchVerification>();
  @Output() referralMatchOptionChange = new EventEmitter<ReferralMatchOption>();

  currentTab = 'eligibleReferrals';

  tabClick($event, tabName) {
    $event.preventDefault();
    this.currentTab = tabName;
  }

  onReferralMatchOptionChange(referralMatchOption: ReferralMatchOption) {
    this.referralVerificationChange.emit(referralMatchOption.verification);
    this.referralMatchOptionChange.emit(referralMatchOption);
  }

  onReferralVerificationChange(referralMatchVerification: ReferralMatchVerification) {
    this.referralVerificationChange.emit(referralMatchVerification);
  }

  get eligibleReferralsOrOptions(): ListingIterable[] {
    return this.referralMatchOptions || this.eligibleReferrals;
  }

  referral(item: ListingIterable): Referral {
    if (item instanceof ReferralMatchOption) {
      return item.referral;
    } else {
      return item as Referral;
    }
  }

  option(item: ListingIterable): ReferralMatchOption {
    if (item instanceof ReferralMatchOption) {
      return item;
    }
  }
}
