/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from '@angular/core';

import { Prescription } from '../../../core/models/prescription.model';

@Component({
  selector: '[app-prescription-list-row]',
  templateUrl: './prescription-list-row.component.html',
  styleUrls: ['./prescription-list-row.component.scss'],
})
export class PrescriptionListRowComponent {
  @Input() prescription: Prescription;
  @Input() showEstimatedValue: boolean;
  @Input() forProvider = false;
  @Input() collapsed: boolean;
}
