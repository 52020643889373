<ng-container *ngIf="provider; else: noProvider">
  <ng-container *ngIf="hasName; else: noName">
    <div>
      {{ provider.fullName }}
      <app-copy-to-clipboard [text]="provider.fullName" />
    </div>
    <div class="small">
      <strong>NPI:</strong>
      <span *ngIf="provider.id && canViewProvider">
        <a
          target="_blank"
          routerLink="/capture-admin/providers/{{provider.npi}}"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ provider.npi }}
        </a>
      </span>
      <span *ngIf="!provider.id || !canViewProvider">
        {{ provider.npi }}
      </span>
      <a target="_blank" href="/capture-admin/providers/{{ provider.npi }}" *ngIf="canViewProvider && linkToProvider && provider.id">
        <fa-icon [icon]="['fas', 'external-link-alt']" size="sm" />
      </a>
      <app-copy-to-clipboard [text]="provider.npi" />
      <div class="small" *ngIf="linkToProvider">
        <strong>NPI Review Status</strong>:
        <span *ngIf="provider && provider.status">
          {{ keyedStatusOptions[provider.status]?.display }}
        </span>
        <span *ngIf="!provider || !provider.status">
          <app-blank-value-dash />
        </span>
      </div>
    </div>
    <div class="small" *ngIf="hasSpecialtiesInfo">
      <p>{{ provider.displaySpecialty }}</p>
    </div>
  </ng-container>
</ng-container>
<ng-template #noName>
  <div>
    <span class="label">NPI:</span>
    {{ provider.npi }}
    <a target="_blank" href="/capture-admin/providers/{{ provider.npi }}" *ngIf="linkToProvider && provider.id">
      <fa-icon [icon]="['fas', 'external-link-alt']" size="sm" />
    </a>
    <app-copy-to-clipboard [text]="provider.npi" />
  </div>
</ng-template>
<ng-template #noProvider> <div><app-blank-value-dash /></div> </ng-template>
