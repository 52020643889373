import { Pipe, PipeTransform } from '@angular/core';
import { MinimalProvider } from 'app/core/models/minimal-provider.model';

@Pipe({
  name: 'providerNameWithNpi',
})
export class ProviderNameWithNpiPipe implements PipeTransform {
  transform(value: MinimalProvider): any {
    if (value) {
      const parts = [];
      parts.push(value.fullName);

      if (value.npi) {
        parts.push(`(NPI ${value.npi})`);
      }

      return parts.join(' ');
    } else {
      return value;
    }
  }
}
