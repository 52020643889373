<div id="consultNoteHeader">
  <div class="border rounded container-fluid p-0" [ngClass]="{ 'inactive': !patientAttachment.client.active }">
    <div class="inactive-label" [ngClass]="{ 'd-none': patientAttachment.client.active }">INACTIVE</div>
    <div class="mx-1 row small-gutters">
      <div class="col-auto">
        <strong>ID</strong>
        <div>{{ patientAttachment.id }}</div>
      </div>
      <div class="col-auto">
        <strong>STATUS</strong>
        <div>{{ statusOptions[patientAttachment.status]?.display }}</div>
      </div>
      <div class="col-auto">
        <div>
          <strong>CLIENT</strong>
          <span *ngIf="!patientAttachment.client.active" class="inactive-client">INACTIVE</span>
        </div>
        <div>
          <a target="_blank" routerLink="/capture-admin/client-management/clients/{{ patientAttachment.client.id }}">
            {{ patientAttachment.client.name }}
          </a>
          <app-copy-to-clipboard [text]="patientAttachment.client.name" />
        </div>
        <div *ngIf="patientAttachment.client.entityTypes">
          Type: {{ patientAttachment.client.entityTypes | joinList }}
        </div>
        <div *ngIf="patientAttachment.ehrAccess" class='ehr-access'>EHR Access</div>
      </div>
      <div class="col-auto">
        <strong>CE-PATIENT</strong>
        <app-patient-name
          [patient]="patientAttachment.patient"
          [canViewPatient]="canViewPatient" />
      </div>
      <div class="col-auto text-center">
        <strong class="m-0 p-0">NEXT EXPIRATION DATE</strong>
        <div>{{ patientAttachment.nextHighValueExpirationDate | date: 'MM/dd/yyyy' }}</div>
        <div class="m-0 p-0"><small>FOR HIGH VALUE WIP</small></div>
      </div>
      <div class="col-auto text-center">
        <strong class="m-0 p-0">ESTIMATED VALUES</strong>
        <app-consult-note-estimated-values [patientAttachment]="patientAttachment" />
        <div class="m-0 p-0"><small>FOR FAXED OFFICE</small></div>
      </div>
      <div class="col-auto">
        <strong>OFFICE</strong>
        <app-consult-note-office [faxedProviderOffice]="patientAttachment.faxedProviderOffice" />
      </div>
      <div class="col-auto">
        <strong>RELATED CONSULT NOTES</strong>
        <ul *ngIf="patientAttachment.relatedPatientAttachments.length > 0; else: noRelated" class="list-unstyled related-consult-note-list">
          <li *ngFor="let rpa of patientAttachment.relatedPatientAttachments">
            <a target="_blank" routerLink="/capture-admin/consult-notes/{{ rpa.id }}">{{ rpa.id }}</a>
            - {{ statusOptions[rpa.status]?.display }}
          </li>
        </ul>
      </div>
      <div class="col-auto">
        <app-consult-note-assigned-to [patientAttachment]="patientAttachment" />
      </div>
    </div>
  </div>
</div>
<ng-template #noRelated> <p>No related consult notes</p></ng-template>
