import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { keyedPatientAttachmentStatusOptions } from '../../../core/options/patient-attachment-status.opts';

@Component({
  selector: 'app-consult-note-header',
  templateUrl: './consult-note-header.component.html',
  styleUrls: ['./consult-note-header.component.scss'],
})
export class ConsultNoteHeaderComponent implements OnInit {
  @Input() patientAttachment: PatientAttachment;

  canViewPatient = false;
  statusOptions = keyedPatientAttachmentStatusOptions;

  constructor(
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.canViewPatient = this.authService.isCaptureAdminUser;
  }
}
