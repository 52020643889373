import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserRoleName } from 'app/core/enums/user-role-name.enum';
import { Capture } from 'app/core/models/capture.model';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';
import { ReferralSpecialty } from 'app/core/models/referral-specialty.model';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { PatientAttachmentsService } from 'app/core/services/patient-attachments.service';
import { ProviderSpecialtyService } from 'app/core/services/provider-specialty.service';
import { debounce } from 'lodash-es';
import { ConsultNoteEditSpecialtyComponent } from '../consult-note-edit-specialty/consult-note-edit-specialty.component';

@Component({
  selector: 'app-consult-note-select-specialty',
  templateUrl: './consult-note-select-specialty.component.html',
  styleUrl: './consult-note-select-specialty.component.scss'
})
export class ConsultNoteSelectSpecialtyComponent implements OnChanges {
  @Input() capture: Capture;
  @Input() patientAttachment: PatientAttachment;

  collapsed = false;
  showEdit = false;
  referralSpecialties: ReferralSpecialty[];
  editModal: NgbModalRef;

  constructor(
    private providerSpecialtyService: ProviderSpecialtyService,
    private patientAttachmentsService: PatientAttachmentsService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {
    this.providerSpecialtyService.getReferralSpecialties().subscribe(
      specialties => this.referralSpecialties = specialties
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['patientAttachment']) {
      const updatedPatientAttachment: PatientAttachment = changes['patientAttachment'].currentValue;

      if (updatedPatientAttachment.selectedSpecialtyFrozen &&
          this.authService.hasPermissionTo(UserRoleName.editVerifiedCaptures)) {
        this.showEdit = true;
      } else {
        this.showEdit = false;
      }
    }
  }

  debouncedSavePatientAttachment = debounce(() => this.savePatientAttachment(), 250, { maxWait: 1000 });

  onSelectedSpecialtyChange() {
    this.debouncedSavePatientAttachment()
  }

  onAdditionalDetailsChanged() {
    this.debouncedSavePatientAttachment();
  }

  onCollapseChange(collapse: boolean) {
    this.collapsed = collapse;
  }

  collapse() {
    this.collapsed = true;
  }

  expand() {
    this.collapsed = false;
  }

  openEditModal($event: Event) {
    $event.preventDefault();

    this.editModal = this.modalService.open(ConsultNoteEditSpecialtyComponent, {
      centered: true,
      size: 'lg'
    });
    this.editModal.componentInstance.patientAttachment = Object.assign({}, this.patientAttachment);
    this.editModal.componentInstance.referralSpecialties = this.referralSpecialties;
  }

  private savePatientAttachment() {
    this.patientAttachmentsService.update(this.patientAttachment).subscribe((pa: PatientAttachment) => {
      const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment);
      modifiedPatientAttachment.selectedSpecialty = pa.selectedSpecialty;
      modifiedPatientAttachment.selectedSpecialtyAdditionalDetails = pa.selectedSpecialtyAdditionalDetails;
      modifiedPatientAttachment.selectedSpecialtyFrozen = pa.selectedSpecialtyFrozen;
      this.patientAttachmentsService.notifyPatientAttachmentChanged(modifiedPatientAttachment);
    });
  }
}
