import { Component, Input, OnInit } from '@angular/core';
import { Patient } from '../../../core/models/patient.model';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PatientListParams, PagedPatientAttachments, PatientAttachmentsService } from '../../../core/services/patient-attachments.service';

@Component({
  selector: 'app-patient-prescriber-consult-notes',
  templateUrl: './patient-prescriber-consult-notes.component.html',
  styleUrls: ['./patient-prescriber-consult-notes.component.scss'],
})
export class PatientPrescriberConsultNotesComponent implements OnInit {
  @Input() patient: Patient;
  @Input() prescriberId: number;

  loading = false;
  patientAttachments: PatientAttachment[] = [];

  constructor(private patientAttachmentsService: PatientAttachmentsService) { }

  ngOnInit() {
    this.loadPatientAttachments();
  }

  private loadPatientAttachments() {
    this.loading = true;

    const params = new PatientListParams();
    params.prescriberId = this.prescriberId;

    this.patientAttachmentsService
      .getList(this.patient.id, params)
      .subscribe((pageResults: PagedPatientAttachments) => {
        this.loading = false;
        this.patientAttachments = pageResults.patientAttachments;
      });
  }
}
