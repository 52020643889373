<div class="toggle-rejection">
  <a href="#rejectionToggle" (click)="onShowRejectionReasonsClick($event)" [ngClass]="{ disabled: anyValuesSelected }">
    <fa-icon *ngIf="!showRejectionReasons" [icon]="['fas', 'plus-square']" size="sm" />
    <fa-icon *ngIf="showRejectionReasons" [icon]="['fas', 'minus-square']" size="sm" />
    <span class="ms-1">
      Do you need to apply this rejection more broadly?
    </span>
  </a>
</div>
<div
  *ngIf="showRejectionReasons"
  class="rejection-reasons mt-3 p-2 border rounded bg-light"
  [ngClass]="{ disabled: disabled }">
  <div class="alert alert-warning border">
    <div class="row">
      <div class="col-1"><fa-icon [icon]="['fas', 'exclamation-triangle']" size="lg" /></div>
      <div class="col">
        Please note, making a selection here will impact future claims. Only make a selection if you are certain you
        would like to reject future claims as described
      </div>
    </div>
  </div>
  <div class="mb-3 ps-2 instructions">
    <strong>OPTIONAL:</strong> If necessary, you can choose to apply these rejections more broadly beyond this task by
    selecting one or more options below:
  </div>
  <div class="form-check mb-2 ps-4" *ngFor="let reasonValue of reasonValues">
    <input
      [id]="reasonValue"
      name="rejectionReason"
      class="form-check-input"
      type="radio"
      [disabled]="disabled"
      (change)="onReasonChange($event, reasonValue)"
      [checked]="reasonSelections[reasonValue]"
      [value]="reasonValue"
    />
    <label [for]="reasonValue" class="form-check-label">
      <ng-container [ngSwitch]="reasonValue">
        <span *ngSwitchCase="reasons.ignorePatient">
          <span>REJECT ALL CLAIMS FOR THIS PATIENT:</span>
          <strong>{{ task.patient.fullName }} DOB {{ task.patient.dob | date: 'MM/dd/yyyy' }}</strong> is not a patient
          of my organization and we do not own their care. <u>ALL</u> open and future claims for this patient should be
          rejected.
        </span>
        <span *ngSwitchCase="reasons.ignorePrescriber">
          <span>REJECT ALL CLAIMS FOR THIS PRESCRIBER:</span> We never work with claims for this prescriber
          <strong>{{ task.provider.fullName }}</strong> as part of our 340B program. <u>ALL</u> open and future claims
          for this prescriber should be rejected.
        </span>
        <span *ngSwitchCase="reasons.ignorePatientPrescriber">
          REJECT ALL CLAIMS FOR THIS PRESCRIBER FOR THIS PATIENT: Our organization does not want to capture claims for
          this prescriber for this patient as part of our 340B program. <u>ALL</u> open and future claims for this
          prescriber for this patient should be rejected.
        </span>
      </ng-container>
    </label>
  </div>
  <div class="mt-3">
    <button class="btn btn-sm btn-danger" [disabled]="disabled" (click)="onClearClick($event)">
      <fa-icon class="text-white" [icon]="['fas', 'times-circle']" /> Clear Selections
    </button>
  </div>
</div>
