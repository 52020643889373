import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Patient } from 'app/core/models/patient.model';
import { PatientService } from 'app/core/services/patient.service';

@Component({
  selector: 'app-capture-admin-patient-encounters',
  templateUrl: './capture-admin-patient-encounters.component.html',
  styleUrls: ['./capture-admin-patient-encounters.component.scss'],
})
export class CaptureAdminPatientEncountersComponent implements OnInit {
  patient: Patient;
  loading: boolean;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.init();
  }

  private init() {
    const patientId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.loading = true;
    this.patientService.get(patientId).subscribe(patient => {
      this.patient = patient;
      this.loading = false;
    });
  }

  fetchPatientEncounters = (paging: any, eligible: boolean) => {
    return this.patientService.pagedEncounters(this.patient.id, eligible, paging);
  }
}
