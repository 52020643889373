import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientReferralMatchOption } from 'app/core/models/client-referral-match-option.model';
import { ClientReferralVerification } from 'app/core/models/client-referral-verification.model';
import { MinimalProvider } from 'app/core/models/minimal-provider.model';

type ListingIterable = ClientReferralMatchOption | ClientReferralVerification;

@Component({
  selector: 'app-referrals-client-listing',
  templateUrl: './referrals-client-listing.component.html',
  styleUrls: ['./referrals-client-listing.component.scss'],
})
export class ReferralsClientListingComponent {
  @Input() loading = false;
  @Input() compactClientView = false;
  @Input() dark = false;

  @Input() referralMatchOptions: ClientReferralMatchOption[];
  @Input() clientReferralVerifications: ClientReferralVerification[];

  @Output() referralVerificationChange = new EventEmitter<ClientReferralMatchOption>();

  onReferralVerificationChange(referralMatchOption: ClientReferralMatchOption) {
    this.referralVerificationChange.emit(referralMatchOption);
  }

  get verificationsOrOptions(): ListingIterable[] {
    return this.referralMatchOptions || this.clientReferralVerifications;
  }

  verification(item: ListingIterable): ClientReferralVerification {
    if (item instanceof ClientReferralMatchOption) {
      return item.clientReferralVerification;
    } else {
      return item as ClientReferralVerification;
    }
  }

  option(item: ListingIterable): ClientReferralMatchOption {
    if (item instanceof ClientReferralMatchOption) {
      return item;
    }
  }
}
