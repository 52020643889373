import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Patient } from '../../../core/models/patient.model';
import { Prescriber } from '../../../core/models/prescriber.model';
import { OutboundFax } from '../../../core/models/outbound-fax.model';
import { OutboundFaxesResult, PatientService } from '../../../core/services/patient.service';
import { OutboundFaxStatus } from '../../../core/enums/outbound-fax-status.enum';

const visibleStatuses = [OutboundFaxStatus.sent, OutboundFaxStatus.failed];

@Component({
  selector: 'app-outbound-faxes',
  templateUrl: './outbound-faxes.component.html',
  styleUrls: ['./outbound-faxes.component.scss'],
})
export class OutboundFaxesComponent implements OnInit, OnChanges {
  outboundFaxes: OutboundFax[] = new Array<OutboundFax>();
  outboundFaxStatuses = OutboundFaxStatus;

  loading = false;
  selectedOutboundFaxId = null;

  @Input() selectedPatient: Patient;
  @Input() selectedPrescriber: Prescriber;
  @Input() selectedOutboundFax: OutboundFax;

  @Output() public selectionChange = new EventEmitter<OutboundFax>();

  constructor(private patientService: PatientService) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init(changes);
  }

  outboundFaxMatchesSelections(outboundFax: OutboundFax): boolean {
    if (outboundFax.prescriberId && outboundFax.patient && this.selectedPrescriber && this.selectedPatient) {
      return (
        outboundFax.patient.id === this.selectedPatient.id && outboundFax.prescriberId === this.selectedPrescriber.id
      );
    } else {
      return false;
    }
  }

  onOutboundFaxChanges(outboundFax: OutboundFax) {
    this.selectionChange.next(outboundFax);
  }

  onClearClick($event) {
    $event.preventDefault();
    this.selectionChange.next(null);
  }

  private init(changes: SimpleChanges = null) {
    this.selectedOutboundFaxId = this.selectedOutboundFax ? this.selectedOutboundFax.id : null;

    if (this.selectedPatient) {
      this.loadOutboundFaxes(changes);
    } else {
      this.outboundFaxes = new Array<OutboundFax>();
    }
  }

  private loadOutboundFaxes(changes: SimpleChanges = null) {
    if (changes && changes.selectedPatient) {
      this.loading = true;

      this.patientService
        .getOutboundFaxes(this.selectedPatient.id, visibleStatuses)
        .subscribe((result: OutboundFaxesResult) => {
          this.outboundFaxes = result.outboundFaxes || new Array<OutboundFax>();
          this.loading = false;
        });
    }
  }
}
