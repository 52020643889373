import { ReferralVerificationReason } from 'app/core/enums/referral-verification-reason.enum';
import { MinimalUser } from 'app/core/models/minimal-user.model';
import { MinimalProvider } from './minimal-provider.model';

export class EhrReferralVerification {
  id: number;
  frozen: boolean;
  referralDate: string;
  referralSpecialty: string;
  reasons: ReferralVerificationReason[] = [];
  otherReason: string;
  notes: string;
  createdAt: Date;
  prescriberNpi: string;
  provider?: MinimalProvider;
  user: MinimalUser;
}
