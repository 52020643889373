import { Component, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppToastService } from 'app/core/services/app-toast.service';
import { isEmpty } from 'lodash-es';
import { MinimalClient } from '../../../core/models/minimal-client.model';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { PrescriberIncorrectInformationRequestsService } from '../../../core/services/prescriber-incorrect-information-requests.service';
import { MinimalProvider } from 'app/core/models/minimal-provider.model';

@Component({
  selector: 'app-prescriber-incorrect-information-prompt',
  templateUrl: './prescriber-incorrect-information-prompt.component.html',
  styleUrls: ['./prescriber-incorrect-information-prompt.component.scss'],
})
export class PrescriberIncorrectInformationPromptComponent {
  @Input() client: MinimalClient;
  @Input() relatedRecordType: string;
  @Input() relatedRecordId: number;
  @Input() provider: MinimalProvider;
  @Input() prescriberId: number;

  providerIncorrectInfoModalEnabled = false;
  comments: string;
  serverError = false;
  validationErrors = new Array<string>();

  constructor(
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private toastService: AppToastService,
    private prescriberIncorrectInformationRequestsService: PrescriberIncorrectInformationRequestsService
  ) {
    this.providerIncorrectInfoModalEnabled = !this.authService.isCaptureAdminUser;
  }

  openModal($event, content) {
    $event.preventDefault();
    this.modalService
      .open(content, { windowClass: 'wide' })
      .result.then(() => this.reset())
      .catch(() => this.reset());
  }

  reset() {
    delete this.comments;
    this.serverError = false;
    this.validationErrors = new Array<string>();
  }

  submit(modal) {
    if (this.validate()) {
      const params = {
        clientId: this.client.id,
        relatedRecordType: this.relatedRecordType,
        relatedRecordId: this.relatedRecordId,
        prescriberId: this.prescriberId,
        comments: this.comments,
      };

      this.prescriberIncorrectInformationRequestsService.create(params).subscribe(
        () => {
          this.toastService.show('Information logged!', { cssClass: 'bg-success-subtle' });
          modal.close();
        },
        (err: HttpErrorResponse) => {
          this.serverError = true;
          console.error(err);
        }
      );
    }
  }

  private validate() {
    this.validationErrors = new Array<string>();

    if (isEmpty(this.comments)) {
      this.validationErrors.push('Comment is required.');
    }

    return this.validationErrors.length === 0;
  }
}
