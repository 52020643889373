import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Patient } from 'app/core/models/patient.model';
import { Prescription } from 'app/core/models/prescription.model';
import { PrescriptionsService, PagedPrescriptions } from 'app/core/services/prescriptions.service';
import { PatientService } from 'app/core/services/patient.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-capture-admin-patient-prescriptions',
  templateUrl: './capture-admin-patient-prescriptions.component.html',
  styleUrls: ['./capture-admin-patient-prescriptions.component.scss'],
})
export class CaptureAdminPatientPrescriptionsComponent implements OnInit {
  patient: Patient;
  loading = true;
  prescriptions: Prescription[];

  constructor(
    private prescriptionsService: PrescriptionsService,
    private patientService: PatientService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.init();
  }

  private init() {
    const patientId = this.getPatientId();
    this.loading = true;

    this.patientService.get(patientId).pipe(
      tap(patient => {
        this.patient = patient;
      }),
      switchMap(() => this.prescriptionsService.getList({ patientId })),
      tap(({ prescriptions }: PagedPrescriptions) => {
        this.prescriptions = prescriptions;
        this.loading = false;
      }),
      catchError((error: HttpErrorResponse) => {
        this.loading = false;
        this.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  private getPatientId(): number {
    const id = this.route.snapshot.paramMap.get('id');
    return parseInt(id, 10);
  }

  private handleError(error: HttpErrorResponse): void {
    console.error(error);
  }
}
