<div class="card mt-2">
  <div class="card-body">
    <h4 class="mb-2">Prescriber</h4>
    <ng-container *ngIf="!selectedPrescriber; else: prescriberSelection">
      <div *ngIf="refreshing; else: prescriberSearchResults"><mat-spinner diameter="30" /></div>
    </ng-container>
  </div>
</div>
<ng-template #prescriberSelection>
  <div class="d-flex prescriber-selection mt-2">
    <app-prescriber-name [provider]="selectedPrescriber.provider" [linkToProvider]="true" />
    <div class="text-danger ms-3 prescriber-clear" (click)="onPrescriberClear()">
      <fa-icon [icon]="['fas', 'times']" />
    </div>
  </div>
</ng-template>
<ng-template #prescriberSearchResults>
  <div class="mt-2" *ngIf="!refreshing && prescribers.length > 0; else: prescriberMessage">
    <h5>Choose Prescriber</h5>
    <div class="choose-prescribers">
      <app-prescriber-list
        [prescribers]="prescribers"
        (prescriberSelect)="onPrescriberSelect($event)"
      />
    </div>
  </div>
</ng-template>
<ng-template #prescriberMessage>
  <p class="mt-2 p-2 bg-light" *ngIf="!selectedPatient">Please select a patient.</p>
  <p class="mt-2 p-2 bg-light" *ngIf="selectedPatient">No prescribers found.</p>
</ng-template>
