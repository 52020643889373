<h3>Inbound Fax Queue</h3>
<div novalidate class="container-fluid p-2 border rounded bg-light">
  <form>
    <div class="row">
      <div class="col-4">
        <div class="filter mb-3">
          <ng-select
            class="w-100"
            bindValue="id"
            bindLabel="humanizedName"
            name="clientIds"
            placeholder="Filter by Client"
            [multiple]="true"
            [items]="clients"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.selectedClientIds"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="filter mb-3">
          <ng-select
            class="w-100"
            bindValue="id"
            bindLabel="fullName"
            name="assignedToIds"
            placeholder="Filter by Assigned Users"
            [multiple]="true"
            [items]="assignedToUsers"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.selectedAssignedToIds"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="filter mb-3">
          <ng-select
            class="w-100"
            bindValue="value"
            bindLabel="display"
            name="statuses"
            placeholder="Filter by Status"
            [multiple]="true"
            [items]="faxStatuses"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.selectedStatuses"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="filter mb-3">
          <strong>Include Archived:</strong>
          <mat-slide-toggle
            name="includeArchived"
            [color]="'primary'"
            [(ngModel)]="filters.includeArchived"
            [disableRipple]="true"
            (change)="onFilterChange()"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-auto pe-1">
        <button class="btn btn-info clear-button" (click)="onClearClick($event)">Clear All</button>
      </div>
      <div class="col-auto">
        <a routerLink="/capture-admin/communications/inbound-faxes/upload" class="btn btn-primary">Upload</a>
      </div>
    </div>
  </form>
</div>
<div *ngIf="!loading; else: spinner">
  <section *ngIf="inboundFaxes.length > 0; else: noFaxes">
    <app-pagination-header
      [label]="'faxes'"
      [paging]="paging"
      (pageSizeChange)="onPageSizeChange($event)"
    />
    <table class="table table-bordered table-hover">
      <thead class="table-dark">
        <tr>
          <th>CLIENT</th>
          <th>STATUS</th>
          <th class="text-nowrap">RECEIVED AT &#x25BC;</th>
          <th>FROM</th>
          <th class="text-center">PAGES</th>
          <th>PATIENT</th>
          <th>PRESCRIBER</th>
          <th>ASSIGNED TO</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let inboundFax of inboundFaxes; let i = index">
          <td>{{ inboundFax.client.humanizedName }}</td>
          <td>{{ inboundFax.status | faxStatus }}{{ inboundFax.archivedAt ? '(ARCHIVED)' : '' }}</td>
          <td class="text-nowrap">{{ inboundFax.receivedAt | date: 'MM/dd/yyyy hh:mm a' }}</td>
          <td class="text-nowrap">
            <fa-icon [icon]="['fas', 'fax']" size="sm" />
            <app-phone-number [number]="inboundFax.callerNumber" [noValue]="'UNKNOWN CALLER'" />
          </td>
          <td class="text-center">{{ inboundFax.pages | number }}</td>
          <td><app-patient-name [patient]="inboundFax.patient" [showAlternateNames]=false /></td>
          <td><app-prescriber-name [provider]="inboundFax.provider" /></td>
          <td>{{ inboundFax.assignedTo?.fullName }}</td>
          <td>
            <ul class="nav">
              <li class="nav-item"><a class="nav-link" href="#" (click)="download($event, inboundFax)"> DOWNLOAD </a></li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/capture-admin/communications/inbound-faxes/{{inboundFax.id}}" (click)="onViewClick(i)">
                  VIEW
                </a>
              </li>
              <li class="nav-item">
                <app-user-presence category="fax" [targetId]="inboundFax.id" />
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </section>
</div>
<ng-template #noFaxes>
  <section><p>There are no faxes.</p></section>
</ng-template>
<ng-template #spinner> <mat-spinner /> </ng-template>
