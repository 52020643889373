<app-capture-search [searchFields]="searchFields" (searchComplete)="onSearchComplete($event)">
  <table class="table table-bordered table-hover">
    <thead class="table-dark">
      <tr>
        <th>CLAIM ID</th>
        <th>VERIFICATION DATE</th>
        <th>STATUS</th>
        <th>SERVICE DATE</th>
        <th>FILL #</th>
        <th>RX REFERENCE</th>
        <th>NDC</th>
        <th>PRESCRIBER</th>
        <th>QUANTITY</th>
        <th>PATIENT NAME</th>
        <th>GENDER</th>
        <th>DOB</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let capture of captures">
        <td>
          <a routerLink="/ce-portal/captures/{{capture.id}}">{{ capture.candidate.claimIdentifier }}</a>
          <div>
            <app-user-presence category="capture" [targetId]="capture.id" />
          </div>
        </td>
        <td>{{ capture.verifiedAt | date: 'MM/dd/yyyy' }}</td>
        <td>{{ capture.status | captureStatus }}</td>
        <td>{{ capture.candidate.serviceDate | date: 'MM/dd/yyyy' }}</td>
        <td>{{ capture.candidate.fillNumber }}</td>
        <td>{{ capture.candidate.referenceNumber }}</td>
        <td>{{ capture.candidate.ndc }}</td>
        <td>{{ capture.provider | providerNameWithNpi }}</td>
        <td>{{ capture.candidate.quantity }}</td>
        <td>{{ capture.patient.fullName }}</td>
        <td>{{ capture.patient.gender }}</td>
        <td>{{ capture.patient.dob | date: 'MM/dd/yyyy' }}</td>
      </tr>
    </tbody>
  </table>
</app-capture-search>
